import type { FC } from 'react';
import { useState } from 'react';
import { Backdrop, CircularProgress, Dialog, DialogContent, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import UIMessage from 'src/shared/components/ui/ui-message';
import ArtifactTags from './artifact-tags';
import { ArtifactModel } from 'src/models/artifact';
import {
    PDFViewer,
    Page,
    Document,
    View,
    Font,
    StyleSheet,
    Text,
    Image,
} from "@react-pdf/renderer";
import { ArtifactFileType, ArtifactImage } from 'src/models/artifact-file';
import { useMount } from 'src/shared/hooks/use-mount';
import ArtifactCertificateForm, { ArtifactCertificateFormDatasources, ArtifactCertificateFormFields } from './artifact-certificate-form';
import { ApiQuery } from 'src/api/api-query';
import { getLookupFormSelectItems, LookupFormSelectLabel } from 'src/shared/utils/get-lookup-select-items';
import { useAuth } from 'src/shared/hooks/use-auth';
import { Permission } from 'src/models/permission';
import { CreateArtifactCertificateCommand } from 'src/api/commands/artifact-certificate';
import { ApiCommand, CreateCommandResult } from 'src/api/api-command';
import { Api } from 'src/api/api';
import { GetArtifactCertificateQuery, GetArtifactCertificateQueryResult } from 'src/api/queries/artifact-certificate/get-artifact-certificate';
import { ArtifactCertificateModel } from 'src/models/artifact-certificate';
import { WreckSite } from 'src/models/wreck-site';
import { coinDescriptionIds, emeraldDescriptionIds } from 'src/models/artifact-description';


interface ArtifactCertificateIssuerProps {
    artifact: ArtifactModel;
    artifactImages: ArtifactImage[];
    onCertificateIssued: (certificate: ArtifactCertificateModel) => void;
    onClose: () => void;
    open?: boolean;
}

export const ArtifactCertificateIssuer: FC<ArtifactCertificateIssuerProps> = ({ onClose, artifact, artifactImages, onCertificateIssued, open = false }) => {
    const auth = useAuth();
    const [errors, setErrors] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [formDatasources, setFormDatasources] = useState<ArtifactCertificateFormDatasources>();
    const [certificate, setCertificate] = useState<ArtifactCertificateModel>();


    useMount(() => {
        reset();

        if (!auth.authState.principal?.hasPermission(Permission.ArtifactsIssueCertificates)) {
            onClose()
            return;
        }

        const load = async () => {
            const curatorDispostions = await getLookupFormSelectItems(ApiQuery.GetCuratorDispositions, LookupFormSelectLabel.None);
            setFormDatasources({ curatorDispositions: curatorDispostions })
        }

        load();
    });


    const handleFormSubmit = async (fields: ArtifactCertificateFormFields): Promise<void> => {
        const { securitySticker, ...rest } = fields;
        const artifactId = artifact.id;

        setIsSubmitting(true);
        try {
            const command: CreateArtifactCertificateCommand = {
                securitySticker: +securitySticker,
                artifactId,
                ...rest
            };

            const commandResult = await Api.executeCommand<CreateArtifactCertificateCommand, CreateCommandResult>(
                ApiCommand.CreateArtifactCertificate,
                command);

            const queryResult = await Api.executeQuery<GetArtifactCertificateQuery, GetArtifactCertificateQueryResult>(
                ApiQuery.GetArtifactCertificate, { artifactId, certificateId: commandResult.id }
            );

            setCertificate(queryResult.record);

            onCertificateIssued(queryResult.record);

        } catch (err) {
            setErrors(err.response.data.errors || [err.response.statusText])
        }

        setIsSubmitting(false);

    }


    const handleClose = (): void => {
            setErrors([]);
            setIsSubmitting(false);
            setCertificate(undefined);
            onClose();
    };

    const reset = () => {
        setErrors([]);
        setIsSubmitting(false);
        setFormDatasources(undefined);
        setCertificate(undefined);
    };


    return (

        <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: 100 }}
                open={isSubmitting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack spacing={3}
                sx={{
                    px: 3,
                    py: 2
                }}>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between">

                    <Typography variant="h5">
                        Issue Artifact Certificate
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={handleClose}
                        disabled={isSubmitting}
                    >
                        <SvgIcon>
                            <XIcon />
                        </SvgIcon>
                    </IconButton>
                </Stack>
                <ArtifactTags artifact={artifact} />
            </Stack>

            <DialogContent>
                <UIMessage
                    type="error"
                    messages={errors}
                    onClose={() => setErrors([])} />
                <Stack spacing={2}>

                    {!certificate && formDatasources && (
                        <ArtifactCertificateForm datasources={formDatasources} onSubmit={handleFormSubmit} />
                    )}

                    {certificate && (
                        <ArtifiactCertificateViewer artifact={artifact} artifactImages={artifactImages} certificate={certificate} />
                    )}
                </Stack>


            </DialogContent>
        </Dialog>

    );
};


interface ArtifiactCertificateViewerProps {
    artifact: ArtifactModel;
    artifactImages: ArtifactImage[];
    certificate: ArtifactCertificateModel;
}

const ArtifiactCertificateViewer: FC<ArtifiactCertificateViewerProps> = ({ artifact, artifactImages, certificate }) => {

    const obverseImage = artifactImages.find(f => f.model?.type === ArtifactFileType.ImageObverse);
    const reverseImage = artifactImages.find(f => f.model?.type === ArtifactFileType.ImageReverse);
    const [CertificateContent, backgroundImageSource] = (() => {

        const wreckSiteId = artifact.wreckSiteId ?? -1;
        const artifactDescriptionId = artifact.artifactDescriptionId ?? -1;
        let certificateContent: FC<ArtifactCertificateContentProps> | undefined;
        let wreckSiteName: string = "other";
        let certificateType: string = "artifact";
        //TOD0: 
        // 1)More templates
        // 2)Fix margarita layouts
        // 3)Refresh Artifact Certs on main page after issue 
        // 4) Contorol file name? 
        switch (wreckSiteId) {
            case WreckSite.Atocha:

                wreckSiteName = "atocha";
                if (coinDescriptionIds.includes(artifactDescriptionId)) {
                    certificateContent = AtochaCoinCertificateContent;
                    certificateType = "coin";
                }else if (emeraldDescriptionIds.includes(artifactDescriptionId)) {
                    certificateContent = AtochaEmeraldCertificateContent;
                    certificateType = "emerald";
                }

                break;
            case WreckSite.Margarita:

                wreckSiteName = "margarita";
                if (coinDescriptionIds.includes(artifactDescriptionId)) {
                    certificateContent = MargaritaCoinCertificateContent;
                    certificateType = "coin";
                } 

                break;

        }

        return [certificateContent, `/assets/reports/coa/coa-${wreckSiteName}-${certificateType}.png`];
    })();

    useMount(() => {
        Font.register({
            family: "Patrick",
            src: "/assets/fonts/patrick.ttf",
        });
    });

    const styles = StyleSheet.create({
        container: {
            display: "flex",
            position: "absolute",
            width: "100%",
        },
        backgroundContainer: {
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
        },
        backgroundImage: {
            height: "100%",
            width: "100%",
        },
        page: {
            fontSize: 10,
            fontWeight: "normal",
            color: '#000',
            fontFamily: "Times-Roman",
        },
        securityStickerNumber: {
            position: "absolute",
            top: 24,
            right: 10,
            width: 40,
        }
    });
    return (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>

                <Page
                    size="A4"
                    orientation="landscape"
                    style={styles.page}
                >
                    {CertificateContent ?
                        <>
                            <View style={styles.backgroundContainer}>
                                <Image src={backgroundImageSource} style={styles.backgroundImage} />
                            </View>
                            <Text style={styles.securityStickerNumber}>{certificate.securitySticker}</Text>
                            <CertificateContent artifact={artifact} certificate={certificate} obverseImage={obverseImage} reverseImage={reverseImage} />
                        </>
                        : <View><Text>Certifcate not yet supported for this wreck site and artifact type.</Text></View>}

                </Page>
            </Document>
        </PDFViewer>
    );

}

const formatRecoveryDate = (dt: Date | undefined)=> {
    if(!dt) {
        return ""
    }
    const date = new Date(dt)
    const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
    const day: string = date.getDate().toString().padStart(2, '0');
    const year: number = date.getFullYear();
    return `${month}/${day}/${year}`;
};

const formatCertifiedOnDate = (dt: Date | undefined)=> {
    if(!dt) {
        return ""
    }
    const date = new Date(dt)
    const options: Intl.DateTimeFormatOptions = { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
      };

    return date.toLocaleDateString('en-US', options);
};

interface ArtifactCertificateContentProps {
    artifact: ArtifactModel;
    certificate: ArtifactCertificateModel;
    obverseImage?: ArtifactImage;
    reverseImage?: ArtifactImage;
}

const AtochaCoinCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, reverseImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 81,
            left: 428,
            width: 120,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
        detailsContainer: {
            position: "absolute",
            top: 16,
            left: 95,
            width: 250,
        },
        coinNumber: {
            position: "absolute",
            top: 0,
        },
        denomination: {
            position: "absolute",
            top: 12,
        },
        reign: {
            position: "absolute",
            top: 24,
        },
        mint: {
            position: "absolute",
            top: 36,
        },
        assayer: {
            position: "absolute",
            top: 48,
        },
        weight: {
            position: "absolute",
            top: 60,
        },
        grade: {
            position: "absolute",
            top: 72,
        },
        date: {
            position: "absolute",
            top: 84,
        },
        comments: {
            position: "absolute",
            top: 96,
        },
        obverseImage: {
            position: "absolute",
            top: 156,
            left: 75,
            width: 100,
        },
        reverseImage: {
            position: "absolute",
            top: 290,
            left: 75,
            width: 100,
        },

    });
    return (
        <>
            <View style={styles.detailsContainer}>
                <Text style={styles.coinNumber}>{artifact.coinNumber}</Text>
                <Text style={styles.denomination}>{artifact.denominationName}</Text>
                <Text style={styles.reign}>{artifact.reignName}</Text>
                <Text style={styles.mint}>{artifact.mintName}</Text>
                <Text style={styles.assayer}>{artifact.assayerName}</Text>
                <Text style={styles.weight}>{artifact.postWeightGm}</Text>
                <Text style={styles.grade}>{artifact.gradeName}</Text>
                <Text style={styles.date}>{artifact.coinDate || "Not Visible"}</Text>
                <Text style={styles.comments}>{artifact.certificateComments || "None"}</Text>
            </View>

            {obverseImage && (
                <Image
                    src={`data:image/jpg;base64,'${obverseImage.data}'`}
                    style={styles.obverseImage} />
            )}

            {reverseImage && (
                <Image
                    src={`data:image/jpg;base64,'${reverseImage.data}'`}
                    style={styles.reverseImage} />
            )}


        <Text style={styles.certifiedOn}>{formatCertifiedOnDate(certificate.createdUtc)}.</Text>
        </>
    );

}

const MargaritaCoinCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, reverseImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 106,
            left: 418,
            width: 120,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
        detailsContainer: {
            position: "absolute",
            top: 20,
            left: 95,
            width: 250,
        },
        coinNumber: {
            position: "absolute",
            top: 0,
        },
        denomination: {
            position: "absolute",
            top: 12,
        },
        reign: {
            position: "absolute",
            top: 24,
        },
        mint: {
            position: "absolute",
            top: 36,
        },
        assayer: {
            position: "absolute",
            top: 48,
        },
        weight: {
            position: "absolute",
            top: 60,
        },
        grade: {
            position: "absolute",
            top: 72,
        },
        date: {
            position: "absolute",
            top: 84,
        },
        comments: {
            position: "absolute",
            top: 96,
        },
        obverseImage: {
            position: "absolute",
            top: 156,
            left: 75,
            width: 100,
        },
        reverseImage: {
            position: "absolute",
            top: 290,
            left: 75,
            width: 100,
        },

    });
    return (
        <>
            <View style={styles.detailsContainer}>
                <Text style={styles.coinNumber}>{artifact.coinNumber}</Text>
                <Text style={styles.denomination}>{artifact.denominationName}</Text>
                <Text style={styles.reign}>{artifact.reignName}</Text>
                <Text style={styles.mint}>{artifact.mintName}</Text>
                <Text style={styles.assayer}>{artifact.assayerName}</Text>
                <Text style={styles.weight}>{artifact.postWeightGm}</Text>
                <Text style={styles.grade}>{artifact.gradeName}</Text>
                <Text style={styles.date}>{artifact.coinDate || "Not Visible"}</Text>
                <Text style={styles.comments}>{artifact.certificateComments || "None"}</Text>
            </View>

            {obverseImage && (
                <Image
                    src={`data:image/jpg;base64,'${obverseImage.data}'`}
                    style={styles.obverseImage} />
            )}

            {reverseImage && (
                <Image
                    src={`data:image/jpg;base64,'${reverseImage.data}'`}
                    style={styles.reverseImage} />
            )}


            <Text style={styles.certifiedOn}>{formatCertifiedOnDate(certificate.createdUtc)}.</Text>
        </>
    );

}

const AtochaEmeraldCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact,certificate, obverseImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 88,
            left: 418,
            width: 120,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
        detailsContainer: {
            position: "absolute",
            top: 17,
            left: 95,
            width: 250,
        },
        tag: {
            position: "absolute",
            top: 0,
        },
        vessel: {
            position: "absolute",
            top: 12,
        },
        recoveryDate: {
            position: "absolute",
            top: 24,
        },
        description: {
            position: "absolute",
            top: 36,
        },
        class: {
            position: "absolute",
            top: 48,
        },
        carat: {
            position: "absolute",
            top: 60,
        },
        comments: {
            position: "absolute",
            top: 72,
        },
        obverseImage: {
            position: "absolute",
            top: 180,
            left: 50,
            width: 200,
        }
    });
    return (
        <>
            <View style={styles.detailsContainer}>
                <Text style={styles.tag}>{artifact.tag}</Text>
                <Text style={styles.vessel}>{artifact.vesselName}</Text>
                <Text style={styles.recoveryDate}>{formatRecoveryDate(artifact.recoveryDate)}</Text>
                <Text style={styles.description}>{artifact.artifactDescriptionName}</Text>
                <Text style={styles.class}>{artifact.artifactClassName}</Text>
                <Text style={styles.carat}>{artifact.carat}</Text>
                <Text style={styles.comments}>{artifact.certificateComments || "None"}</Text>
            </View>

            {obverseImage && (
                <Image
                    src={`data:image/jpg;base64,'${obverseImage.data}'`}
                    style={styles.obverseImage} />
            )}


            <Text style={styles.certifiedOn}>{formatCertifiedOnDate(certificate.createdUtc)}.</Text>
        </>
    );
}