export interface WreckSiteRow {
    id: number;
    name: string;
    operationId: number;
    operationName: string;
    createdUtc: Date;
}

export enum WreckSite {
    Atocha = 2,
    Margarita = 3,
    Fleet1715 = 4
}
