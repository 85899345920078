import { FormControl, FormControlLabel, Switch } from "@mui/material";
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormFieldHandle } from "./form-field";

interface FormSwitchProps {
    label: string;
    name: string;
    initialValue: boolean;
    onChange: (key: string, value: boolean | null) => void;
    disabled?: boolean;
}

export interface FormSwitchHandle extends FormFieldHandle {
    getValue: () => boolean;
    setValue: (value: boolean) => void;
};

 const FormSwitch = forwardRef<FormSwitchHandle, FormSwitchProps>(({ label, name, initialValue, onChange, disabled = false}, ref) => {
    const [fieldValue, setFieldValue] = useState<boolean>(initialValue);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(disabled);

    useImperativeHandle(ref, () => ({
        getValue() {
            return fieldValue;
        },
        setValue(value: any) {
            changeFieldValue(value);
        },
        resetValue() {
            changeFieldValue(initialValue);
        },
        isDisabled() {
            return fieldDisabled;
        },
        setDisabled(value: boolean) {
            setFieldDisabled(value);
        },
    }));

    const handleFieldChanged = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        changeFieldValue(checked);
    }

    const changeFieldValue = (value: any) => {
        setFieldValue(value);
        onChange(name, value);
    }

    useEffect(()=>{
        setFieldDisabled(disabled);
    },[disabled])


    return (
        <FormControl>
            <FormControlLabel
                control={(
                    <Switch
                        name={name}
                        checked={fieldValue}
                        onChange={handleFieldChanged}
                        disabled={fieldDisabled}
                    />
                )}
                label={label}
                sx={{color:'#6C737F'}}
            />

        </FormControl>
    )
});

export default FormSwitch;