export enum ApiCommand {
	//Principal
	Authenticate ,
	RefreshToken,
	Register,
	RequestPasswordReset,
	ResetPassword,

	//User
	CreateUser,
	UpdateUser,

	//IP Address
	CreateIpAddress,
	DeleteIpAddress,

	//ArtifactDescription
	CreateArtifactDescription,
	UpdateArtifactDescription,

	//GalleonSection
	CreateGalleonSection,
	UpdateGalleonSection,

	//GalleonActivityArea
	CreateGalleonActivityArea,
	UpdateGalleonActivityArea,

	//Artifact
	CreateArtifact,
	UpdateArtifact,
	CreateArtifactFile,
	DeleteArtifactFile,

	//Building
	CreateBuilding,
	UpdateBuilding,

	//Safe
	UpdateSafe,
	CreateSafe,
	
	//Shelf
	CreateShelf,
	UpdateShelf,

	//Bin
	CreateBin,
	UpdateBin,

	//Artifact Certificate
	CreateArtifactCertificate
}

export interface CreateCommandResult {
	id: number;
}

export interface UpdateCommandResult {
}

// export interface DeleteCommandResult { 
//     result: boolean;
// }