import { FC, useState } from 'react';
import UIForm, { UIFormMode } from 'src/shared/components/ui/ui-form';
import UITextField, { UITextFieldType } from 'src/shared/components/ui/ui-text-field';
import { BaseArtifactCommand } from 'src/api/commands/artifact';
import { nameof } from 'src/shared/utils/nameof';
import UISelectField, { UISelectFieldItem, UISelectFieldType } from 'src/shared/components/ui/ui-select-field';
import { UIFormSection } from 'src/shared/components/ui/ui-form-section';
import UIDateField from 'src/shared/components/ui/ui-date-field';
import { FormikType } from 'src/shared/components/ui/ui-field';
import { coinDescriptionIds, emeraldDescriptionIds, pearlDescriptionIds, silverIngotDescriptionIds } from 'src/models/artifact-description';


export interface ArtifactFormFields extends BaseArtifactCommand {
  id?: number;
  isActive?: boolean;
  divisonEligibleNumber: number; //UIForm proxies selected number to boolean
  highTicketNumber: number; //UIForm proxies selected number to boolean
}

interface ArtifactFormDatasources {

  operations: UISelectFieldItem<number>[];
  vessels: UISelectFieldItem<number>[];
  wreckSites: UISelectFieldItem<number>[];
  artifactDescriptions: UISelectFieldItem<number>[];
  dispositions: UISelectFieldItem<number>[];
  artifactTypes: UISelectFieldItem<number>[];
  conservators: UISelectFieldItem<number>[];
  artifactStatuses: UISelectFieldItem<number>[];
  concretionRemovals: UISelectFieldItem<number>[];
  protocols: UISelectFieldItem<number>[];
  chlorideRemovals: UISelectFieldItem<number>[];
  chlorideReleaseTests: UISelectFieldItem<number>[];
  chemicalStabilizations: UISelectFieldItem<number>[];
  curatorDispositions: UISelectFieldItem<number>[];
  conservationResults: UISelectFieldItem<number>[];
  divisionEligibilityOptions: UISelectFieldItem<number>[];
  owners: UISelectFieldItem<number>[];
  pearlGrades: UISelectFieldItem<number>[];
  artifactClasses: UISelectFieldItem<number>[];
  denominations:UISelectFieldItem<number>[];
  mints:UISelectFieldItem<number>[];
  reigns:UISelectFieldItem<number>[];
  grades:UISelectFieldItem<number>[];
  assayers:UISelectFieldItem<number>[];

  highTicketOptions: UISelectFieldItem<number>[];
}

export interface ArtifactFormData {
  mode: UIFormMode;
  datasources: ArtifactFormDatasources;
  fields: ArtifactFormFields
}

interface ArtifactFormProps {
  entityName: string;
  cancelHref: string;
  data: ArtifactFormData;
  onSave: (mode: UIFormMode, data: ArtifactFormFields) => Promise<number>;
  onCompleted: (id: number) => Promise<void>;
}

const ArtifactForm: FC<ArtifactFormProps> = (props) => {

  const { entityName, cancelHref, data: { mode, datasources, fields }, onSave, onCompleted } = props;
  const [pearlSectionVisible, setPearlSectionVisible] = useState<boolean>(false);
  const [coinSectionVisible, setCoinSectionVisible] = useState<boolean>(false);
  const [emeraldSectionVisible, setEmeraldSectionVisible] = useState<boolean>(false);
  const [silverIngotSectionVisible, setSilverIngotSectionVisible] = useState<boolean>(false);


  const onLoad = async (formik: FormikType) => {
    setFormSectionVisiblity(fields.artifactDescriptionId);
  };

  const onBeforeSave = async (mode: UIFormMode, form: ArtifactFormFields) => {
    form.divisible = form.divisonEligibleNumber === 1 ? true : false;
    form.highTicket = form.highTicketNumber === 1 ? true : false;
    return form;
  };

  const onArtifactDescriptionChanged = async(name: string, value: number | number[], formik: FormikType) => {
   const artifactDescriptionId = value as number; //Single select
    setFormSectionVisiblity(artifactDescriptionId);
   //formik.setFieldValue(nameof(fields, "artifactTypeId"),19);
  }

  const setFormSectionVisiblity = (artifactDescriptionId: number = 0) => {
    setPearlSectionVisible(pearlDescriptionIds.indexOf(artifactDescriptionId) > -1);
    setCoinSectionVisible(coinDescriptionIds.indexOf(artifactDescriptionId) > -1);
    setEmeraldSectionVisible(emeraldDescriptionIds.indexOf(artifactDescriptionId) > -1);
    setSilverIngotSectionVisible(silverIngotDescriptionIds.indexOf(artifactDescriptionId) > -1);
  };

  // const setArtifactDescriptionDefaults = (artifactDescriptionId: number, formik: FormikType) => { 
  //   formik.setFieldValue(nameof(fields, "artifactTypeId"),19);
  // };

  return (
    <UIForm
      onLoad={onLoad}
      useCustomSections={true}
      mode={mode}
      entity={entityName}
      cancelHref={cancelHref}
      onBeforeSave={onBeforeSave}
      onSave={onSave}
      onCompleted={onCompleted}
      fields={fields}>

      <UIFormSection title="Artifact Details">
        <UITextField
          name={nameof(fields, "tag")}
          label="Tag #"
          required={false} />

        <UIDateField
          name={nameof(fields, "recoveryDate")}
          label="Date Recovered"
          required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "operationId")}
          label="Operation"
          items={datasources.operations}
          required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "vesselId")}
          label="Vessel"
          items={datasources.vessels}
          required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "wreckSiteId")}
          label="Wreck Site"
          items={datasources.wreckSites}
          required={false} />

        <UITextField
          name={nameof(fields, "quantity")}
          label="Quantity"
          type={UITextFieldType.Number}
          required={false} />

        <UISelectField
            type={UISelectFieldType.Single}
            name={nameof(fields, "artifactDescriptionId")}
            label="Description"
            items={datasources.artifactDescriptions}
            onChange={onArtifactDescriptionChanged}
            required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "artifactTypeId")}
          label="Type"
          items={datasources.artifactTypes}
          required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "curatorDispositionId")}
          label="Disposition"
          items={datasources.curatorDispositions}
          required={false} />

      </UIFormSection>

      <UIFormSection title="Coin Details" hidden={!coinSectionVisible}>
      <UITextField
            name={nameof(fields, "coinNumber")}
            label="Coin Number"
            type={UITextFieldType.Text}
            required={false} />  

        <UISelectField
            type={UISelectFieldType.Single}
            name={nameof(fields, "denominationId")}
            label="Denomination"
            items={datasources.denominations}
            required={false} />
        
        <UISelectField
            type={UISelectFieldType.Single}
            name={nameof(fields, "mintId")}
            label="Mint"
            items={datasources.mints}
            required={false} />

        <UISelectField
            type={UISelectFieldType.Single}
            name={nameof(fields, "reignId")}
            label="Reign"
            items={datasources.reigns}
            required={false} />

        <UISelectField
            type={UISelectFieldType.Single}
            name={nameof(fields, "assayerId")}
            label="Assayer"
            items={datasources.assayers}
            required={false} />
        <UITextField
            name={nameof(fields, "coinDate")}
            label="Coin Date"
            type={UITextFieldType.Text}
            required={false} />  
        <UISelectField
            type={UISelectFieldType.Single}
            name={nameof(fields, "gradeId")}
            label="Grade"
            items={datasources.grades}
            required={false} />
      </UIFormSection>

      <UIFormSection title="Pearl Details" hidden={!pearlSectionVisible}>
        <UISelectField
            type={UISelectFieldType.Single}
            name={nameof(fields, "pearlGradeId")}
            label="Pearl Grade"
            items={datasources.pearlGrades}
            required={false} />
      </UIFormSection>

      <UIFormSection title="Emerald Details" hidden={!emeraldSectionVisible}>
        <UISelectField
            type={UISelectFieldType.Single}
            name={nameof(fields, "artifactClassId")}
            label="Class"
            items={datasources.artifactClasses}
            required={false} />
        <UITextField
            name={nameof(fields, "carat")}
            label="Carat"
            type={UITextFieldType.Number}
            required={false} />  
      </UIFormSection>

      <UIFormSection title="Silver Ingot Details" hidden={!silverIngotSectionVisible}>
      <UITextField
            name={nameof(fields, "manifest")}
            label="Manifest"
            type={UITextFieldType.Text}
            required={false} />

      <UITextField
            name={nameof(fields, "classFactor")}
            label="Class Factor"
            type={UITextFieldType.Text}
            required={false} />

        <UITextField
            name={nameof(fields, "measurements")}
            label="Measurements"
            type={UITextFieldType.Text}
            required={false} />  

        <UITextField
            name={nameof(fields, "karats")}
            label="Karats"
            type={UITextFieldType.Text}
            required={false} />    

        <UITextField
            name={nameof(fields, "weightTroyPounds")}
            label="Weight (Troy lb)"
            type={UITextFieldType.Text}
            required={false} />   

       <UITextField
            name={nameof(fields, "weightTroyOunces")}
            label="Weight (Troy oz)"
            type={UITextFieldType.Text}
            required={false} />    

      </UIFormSection>

      <UIFormSection title="Conservation">
        <UIDateField
          name={nameof(fields, "inDate")}
          label="Date checked in to lab"
          required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "conservatorId")}
          label="Conservator"
          items={datasources.conservators}
          required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "protocolId")}
          label="Protocol"
          items={datasources.protocols}
          required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "artifactStatusId")}
          label="Status"
          items={datasources.artifactStatuses}
          required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "concretionRemovalId")}
          label="Concretion Removal"
          items={datasources.concretionRemovals}
          required={false} />
      </UIFormSection>
      <UIFormSection title="Recovery Coordinates">

        <UITextField
          name={nameof(fields, "latitude")}
          label="Latitude"
          type={UITextFieldType.Number}
          required={false} />

        <UITextField
          name={nameof(fields, "longitude")}
          label="Longitude"
          type={UITextFieldType.Number}
          required={false} />

      </UIFormSection>
      <UIFormSection title="Measurements (Pre/Post Conservation)">
        <UITextField
          name={nameof(fields, "preHeightCm")}
          label="Height (Pre)"
          required={false} />
        <UITextField
          name={nameof(fields, "postHeightCm")}
          label="Height (Post)"
          required={false} />
        <UITextField
          name={nameof(fields, "preWidthCm")}
          label="Width (Pre)"
          required={false} />
        <UITextField
          name={nameof(fields, "postWidthCm")}
          label="Width (Post)"
          required={false} />
        <UITextField
          name={nameof(fields, "preLengthCm")}
          label="Length (Pre)"
          required={false} />
        <UITextField
          name={nameof(fields, "postLengthCm")}
          label="Length (Post)"
          required={false} />
       <UITextField
          name={nameof(fields, "preWeightGm")}
          label="Weight (Pre)"
          required={false} />
        <UITextField
          name={nameof(fields, "postWeightGm")}
          label="Weight (Post)"
          required={false} />
      </UIFormSection>

      <UIFormSection title="Chloride Removal Process">
        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "chlorideRemovalId")}
          label="Chloride Removal Method"
          items={datasources.chlorideRemovals}
          required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "chlorideReleaseTestId")}
          label="Chloride Release Test"
          items={datasources.chlorideReleaseTests}
          required={false} />

        <UIDateField
          name={nameof(fields, "clInDate")}
          label="CL Start Date"
          required={false} />
        <UIDateField
          name={nameof(fields, "clOutDate")}
          label="CL End Date"
          required={false} />
      </UIFormSection>
      <UIFormSection title="Chemical Stabilization Process">
        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "chemicalStabilizationId")}
          label="Chemical Stabilization Method"
          items={datasources.chemicalStabilizations}
          required={false} />

        <UIDateField
          name={nameof(fields, "chemInDate")}
          label="Chem Start Date"
          required={false} />

        <UIDateField
          name={nameof(fields, "chemOutDate")}
          label="Chem End Date"
          required={false} />
      </UIFormSection>
      <UIFormSection title="Lab Disposition">
        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "conservationResultId")}
          label="Conservation Result"
          items={datasources.conservationResults}
          required={false} />

        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "dispositionId")}
          label="Lab Disposition"
          items={datasources.dispositions}
          required={false} />

        <UIDateField
          name={nameof(fields, "curatorDispositionDate")}
          label="Lab Disposition Date"
          required={false} />
      </UIFormSection>

      <UIFormSection title="Admin Info">
        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "divisonEligibleNumber")}
          label="Divisible"
          items={datasources.divisionEligibilityOptions}
          required={false} />

        <UITextField
          name={nameof(fields, "points")}
          label="Points"
          type={UITextFieldType.Text}
          required={false} />

        <UITextField
          name={nameof(fields, "value")}
          label="Value"
          type={UITextFieldType.Number}
          required={false} />

        <UITextField
          name={nameof(fields, "investor")}
          label="Investor"
          required={false} />


        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "ownerId")}
          label="Owner"
          items={datasources.owners}
          required={false} />


        <UISelectField
          type={UISelectFieldType.Single}
          name={nameof(fields, "highTicketNumber")}
          label="High Ticket"
          items={datasources.highTicketOptions}
          required={false} />
      </UIFormSection>

      <UIFormSection title="Comments">
        <UITextField
          name={nameof(fields, "certificateComments")}
          label="Certificate Comments"
          multilineRows={5}
          required={false} />


        <UITextField
          name={nameof(fields, "comments")}
          label="Public Comments"
          multilineRows={5}
          required={false} />

        <UITextField
          name={nameof(fields, "internalComments")}
          label="Internal Comments"
          multilineRows={5}
          required={false} />


      </UIFormSection>
    </UIForm>
  );
};

export default ArtifactForm;