


export enum ArtifactFileCategory {
    Image = 10,
    Document = 20
}

export enum ArtifactFileType {
    ImageDefault = 10,
    ImagePreConservation = 20,
    ImageObverse = 30,
    ImageReverse = 40,
    ImageCard = 50,
    ImageAdditional = 60,
    Attachment = 70
}

export const ArtifactFileTypeDisplayNames: { [key in ArtifactFileType]: string } = {
    [ArtifactFileType.ImageDefault]: "Default",
    [ArtifactFileType.ImagePreConservation]: "Pre Conservation",
    [ArtifactFileType.ImageObverse]: "Front",
    [ArtifactFileType.ImageReverse]: "Back",
    [ArtifactFileType.ImageCard]: "Card",
    [ArtifactFileType.ImageAdditional]: "Additional",
    [ArtifactFileType.Attachment]: "Attachment"
};

export interface ArtifactFileModel {
    id: number;
    artifactId?: number;
    type: ArtifactFileType;
    category: ArtifactFileCategory;
    blobPath: string;
    blobName: string;
    blobExtension: string;
    title?: string;
    sizeBytes: number;
    label?: string;
    width?: number;
    height?: number;
    resolutionX?: number;
    resolutionY?: number;
    hasThumbnail?: boolean;
    createdByUserId?: number;
    createdUtc: Date;
}

export interface ArtifactImage {
    data: string;
    model?: ArtifactFileModel
    label: string;
  }

  export const ArtifactLoadingImage = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";