import * as React from 'react';
import Switch from '@mui/material/Switch';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Permission } from 'src/models/permission';
import { splitCamelCase } from 'src/shared/utils/split-camel-case';

interface PermissionSelectorProps {
    initialPermissions: number[] | null;
    onPermissionsChanged: (permissions: number[])=> void;
}

export const PermissionSelector: React.FC<PermissionSelectorProps> = (props) => {
    const {initialPermissions, onPermissionsChanged } = props;
    const [checkedPermissions, setCheckedPermissions] = React.useState<number[]>(initialPermissions || []);
    const onSwitchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const remove = !event.target.checked;
        const value = Number(event.target.value);
        if (remove) {
            setCheckedPermissions(p => {
                const arr = p.filter(n => n !== value);
                onPermissionsChanged(arr);
                return arr;
            })
        } else {
            setCheckedPermissions(p => {
                const uniqueSet = new Set([...p, value]);
                const arr = Array.from(uniqueSet);
                onPermissionsChanged(arr);
                return arr;
            })
        }

    }

    const permissions: Permission[] = [
        Permission.ArtifactsRead,
        Permission.ArtifactsCreate,
        Permission.ArtifactsEdit,
        Permission.ArtifactsTransfer,
        Permission.ArtifactsCreateImages,
        Permission.ArtifactsDeleteImages,
        Permission.ArtifactsIssueCertificates,
        Permission.ArtifactsExportLatLon,

        Permission.DataManagementRead,
        Permission.DataManagementCreate,
        Permission.DataManagementEdit,

        Permission.DivisionManagementRead,
        Permission.DevelopmentFeatures
    ]

    return (
     
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Permission
                        </TableCell>
                        <TableCell>
                            Enabled
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {permissions.map((permission, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {splitCamelCase(Permission[permission])}
                            </TableCell>
                            <TableCell>
                                <Switch
                                    name={Permission[permission]}
                                    value={permission}
                                    checked={checkedPermissions.indexOf(permission) > -1}
                                    color="primary"
                                    edge="start"
                                    onChange={onSwitchChanged} />
                            </TableCell>
                        </TableRow>
                    ))}
    
                </TableBody>
            </Table>
      

    );
}