import { createRef, FC, useRef } from 'react';
import {
    Button,
    IconButton,
    Stack,
    SvgIcon,
    Typography,
} from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { nameof } from 'src/shared/utils/nameof';
import FormSelect, { FormSelectHandle, FormSelectItem } from 'src/shared/components/form/form-select';
import FormTextInput, { FormTextInputHandle } from 'src/shared/components/form/form-text-input';
import FormSwitch, { FormSwitchHandle } from 'src/shared/components/form/form-switch';
import { FormFieldHandle } from 'src/shared/components/form/form-field';

export interface ArtifactCertificateFormFields {
    securitySticker: string;
    curatorDispositionId: number;
    internalCommentsAppend: string;
    isRecertification: boolean;
}

export interface ArtifactCertificateFormDatasources {
    curatorDispositions: FormSelectItem<number>[];
}

interface ArtifactCertificateFormProps {
    datasources: ArtifactCertificateFormDatasources;
    onSubmit: (fields: ArtifactCertificateFormFields) => void;
    onReset?: () => void;
}

const fieldDefaults: ArtifactCertificateFormFields = {
    securitySticker: "",
    isRecertification: false,
    curatorDispositionId: -1,
    internalCommentsAppend: "",
}

const ArtifactCertificateForm: FC<ArtifactCertificateFormProps> = (props) => {
    const defaultSelectOptions = [{ label: "< None >", value: -1 }]
    const {
        datasources = {
            curatorDispositions: defaultSelectOptions,
        },
        onSubmit,
        onReset,
    } = props;



    //Refs
    const fieldRefs = {
        securityStickerNumber: createRef<FormTextInputHandle>(),
        isRecertification: createRef<FormSwitchHandle>(),
        curatorDispositionId: createRef<FormSelectHandle>(),
        internalCommentsAppend: createRef<FormTextInputHandle>(),
    }

    const fieldValuesRef = useRef<ArtifactCertificateFormFields>({
        ...fieldDefaults
    });


    const onFieldValueChanged = (field: string, value: any | null) => {
        //Update ref does not cause unnecessary renders
        const newFields: ArtifactCertificateFormFields = {
            ...fieldValuesRef.current,
            [field]: value || undefined,
        }
        fieldValuesRef.current = newFields;
    };

    const onFormSubmit = () => {
        onSubmit?.(fieldValuesRef.current);
    }

    const onFormReset = () => {

        fieldValuesRef.current = { ...fieldDefaults };
        for (const fieldKey in fieldRefs) {
            const fieldHandle = fieldRefs[fieldKey as keyof typeof fieldRefs] as React.RefObject<FormFieldHandle>;
            fieldHandle?.current?.resetValue();
        }
        onReset?.();
    }

    return (
        <>

            <Stack spacing={1}>

                <Typography variant="h6">
                    Certificate Details
                </Typography>
                <FormTextInput
                    ref={fieldRefs.securityStickerNumber}
                    label="Security Sticker #"
                    name={nameof<ArtifactCertificateFormFields>("securitySticker")}
                    initialValue={fieldDefaults.securitySticker.toString()}
                    type="number"
                    onChange={onFieldValueChanged} />
                <FormSwitch
                    ref={fieldRefs.isRecertification}
                    label="Recertification"
                    name={nameof<ArtifactCertificateFormFields>("isRecertification")}
                    initialValue={fieldDefaults.isRecertification}
                    onChange={onFieldValueChanged} />

                <Typography variant="h6">
                Artifact Modifications
                </Typography>
                <FormSelect
                    ref={fieldRefs.curatorDispositionId}
                    label="Curator Dispostion"
                    name={nameof<ArtifactCertificateFormFields>("curatorDispositionId")}
                    initialValue={fieldDefaults.curatorDispositionId}
                    datasource={datasources.curatorDispositions}
                    onChange={onFieldValueChanged} />

                <FormTextInput
                    ref={fieldRefs.internalCommentsAppend}
                    label="Internal Comments (Append)"
                    name={nameof<ArtifactCertificateFormFields>("internalCommentsAppend")}
                    initialValue={fieldDefaults.internalCommentsAppend}
                    onChange={onFieldValueChanged} />

            </Stack>

            <Stack
                direction="row"
                justifyContent="right"
                alignItems="flex-end"
                sx={{ pt: 3, pr: 3 }}>
                <IconButton
                    sx={{ mr: 2 }}
                    onClick={onFormReset}
                >
                    <SvgIcon>
                        <RestartAltOutlinedIcon />
                    </SvgIcon>
                </IconButton>
                <Button
                    startIcon={(
                        <SvgIcon>
                            <PostAddIcon  />
                        </SvgIcon>
                    )}
                    variant="contained"
                    onClick={onFormSubmit}
                >
                    Issue Certificate
                </Button>
            </Stack>
        </>



    );


}



export default ArtifactCertificateForm;