import { GetLookupRowsQuery, GetLookupRowsQueryResult } from "src/api/queries/lookup/get-lookup-rows";
import { UISelectFieldItem } from "../components/ui/ui-select-field";
import { ApiQuery } from "src/api/api-query";
import { Api } from "src/api/api";
import { FormSelectItem } from "../components/form/form-select";


export enum LookupSelectFieldItemLabel
{
    All,
    None
}

export const  getLookupSelectFieldItems = async(apiQuery: ApiQuery, label: LookupSelectFieldItemLabel): Promise<UISelectFieldItem<number>[]> => {
    const result = await Api.executeQuery<GetLookupRowsQuery, GetLookupRowsQueryResult>(apiQuery, {});
    const rows = result?.rows.map<UISelectFieldItem<number>>(r => ({ label: r.name, value: r.id })) || [];
    rows.unshift({value: -1, label: label === LookupSelectFieldItemLabel.All ? "All" : "< None >"})
    return rows;
}

export enum LookupFormSelectLabel
{
    All,
    None
}


export const getLookupFormSelectItems = async(apiQuery: ApiQuery,label: LookupFormSelectLabel): Promise<FormSelectItem<number>[]> => {
    const result = await Api.executeQuery<GetLookupRowsQuery, GetLookupRowsQueryResult>(apiQuery, {});
    const rows = result?.rows.map<FormSelectItem<number>>(r => ({ label: r.name, value: r.id })) || [];
    rows.unshift({ value: -1, label: label === LookupFormSelectLabel.All ? "All" : "< None >" })
    return rows;
}

