export const pearlDescriptionIds = [14,277,314];
export const coinDescriptionIds = [191,239,1001,294,349,1262,639,1205,641,642,644,646];
export const emeraldDescriptionIds = [230,231,241,239,244,1178,500,275,314,276,1207,902,904];
export const silverIngotDescriptionIds = [613,614,615,1116];

export interface ArtifactDescriptionModel {
    id: number;
    name: string;
    isActive: boolean;
    createdUtc: Date;
    defaultDivisionPoints: number;
    defaultDivisonEligible: boolean;
    defaultArtifactTypeId?: number;
    defaultConservatorId?: number;
    defaultArtifactStatusId?: number;
    defaultConcretionRemovalMethodId?: number;
    defaultProtocolId?: number;
    defaultChlorideRemovalId?: number;
    defaultChlorideReleaseTestId?: number;
    defaultChemicalStabilizationId?: number;
    defaultDispositionId?: number;
}

export interface ArtifactDescriptionRow extends ArtifactDescriptionModel {
    defaultArtifactTypeName?: string;
    defaultConservatorName?: string;
    defaultArtifactStatusName?: string;
    defaultConcretionRemovalMethodName?: string;
    defaultProtocolName?: string;
    defaultChlorideRemovalName?: string;
    defaultChlorideReleaseTestName?: string;
    defaultChemicalStabilizationName?: string;
    defaultDispositionName?: string;
}
