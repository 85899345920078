
import { GridColDef } from '@mui/x-data-grid';
import { UIDataGrid } from 'src/shared/components/ui/ui-data-grid';
import { FC, useCallback, useState } from 'react';
import { useMount } from 'src/shared/hooks/use-mount';
import { Api } from 'src/api/api';
import { ApiQuery } from 'src/api/api-query';
import UIMessage from 'src/shared/components/ui/ui-message';
import { paths } from 'src/routes/paths';
import { nameof } from 'src/shared/utils/nameof';
import { idColumn, renderColumn, dateColumn, textColumn, imageDataColumn, deleteActionColumn } from 'src/shared/components/grid-columns';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useNavigate, useParams } from 'react-router';
import { Permission } from 'src/models/permission';
import { ArtifactFileCategory, ArtifactFileModel, ArtifactFileType, ArtifactFileTypeDisplayNames } from 'src/models/artifact-file';
import { GetArtifactFilesQuery, GetArtifactFilesQueryResult } from 'src/api/queries/artifact-file/get-artifact-files';
import { Typography, Button, SvgIcon, Card, Link } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import { Seo } from 'src/shared/components/seo';
import Upload01Icon from '@untitled-ui/icons-react/build/esm/Upload01';
import { useDialog } from 'src/shared/hooks/use-dialog';
import { ArtifactImageUploadResult, ArtifactImageUploader } from './components/artifact-image-uploader';
import { RouterLink } from 'src/shared/components/router-link';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import ArtifactTags from './components/artifact-tags';
import { ArtifactModel } from 'src/models/artifact';
import { GetArtifactQuery, GetArtifactQueryResult } from 'src/api/queries/artifact/get-artifact';
import UIConfirmDialog from 'src/shared/components/ui/ui-confirm-dialog';
import { DeleteArtifactFileCommand, DeleteArtifactFileCommandResult } from 'src/api/commands/artifact-file';
import { ApiCommand } from 'src/api/api-command';


interface ArtifactImageListModel extends ArtifactFileModel {
    imageTypeName: string;
    imageData?: string;
}


const ArtifactImagesPage: FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const uploadDialog = useDialog();
    const { id } = useParams();
    const artifactId = +(id || 0);
    const title = "Manage Artifact Images"
    const backPath = paths.artifacts.list + "#" + artifactId;
    const backTitle = "Artifact Details";
    const pageSize = 25;
    const imageHeight = 150;

    const permissions = [Permission.ArtifactsCreateImages,Permission.ArtifactsDeleteImages ];
    const uploadButtonAccessible = auth.authState.principal?.hasPermission(Permission.ArtifactsCreateImages) || false;
    const deleteButtonAccessible = auth.authState.principal?.hasPermission(Permission.ArtifactsDeleteImages) || false;

    const loadingRows: any[] = ((rows: number) => {
        return [...Array(rows)].map((row, index) => {
            return {
                id: Number.MAX_SAFE_INTEGER - index,
                name: null,
                isActive: null,
            }
        });
    })(pageSize);



    const [artifact, setArtifact] = useState<ArtifactModel>();
    const [rows, setRows] = useState<any[]>(loadingRows);
    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<string[]>([]);
	const [deleteRecord, setDeleteRecord] = useState<{id: number, name: string}>();


	const handleDeleteConfirmed = async(artifactId: number, fileId: number) => {

        setLoading(true);

        const deleteArtifactFileCommandResult = await Api.executeCommand<DeleteArtifactFileCommand, DeleteArtifactFileCommandResult>(
            ApiCommand.DeleteArtifactFile, { artifactId, fileId });
         
        if(deleteArtifactFileCommandResult.result) {
            await loadArtifactImages(artifactId);
        }else {
            setErrors(["Error deleting artifact image. Please try again."]);
        }
       
		setDeleteRecord(undefined);

        setLoading(false);
	};

	const handleDeleteCanceled = () => {
		setDeleteRecord(undefined);
	};


    const loadArtifact = useCallback(async (id: number) => {
        try {
            const getArtifactQueryResult = await Api.executeQuery<GetArtifactQuery, GetArtifactQueryResult>(
                ApiQuery.GetArtifact, { id }
              );
              

            setArtifact(getArtifactQueryResult.record)

        } catch (err) {
            setErrors(err.response.data.errors || [err.response.statusText])
        }
    },[])

    const loadArtifactImages = useCallback(async (id: number) => {
        try {
              
            const getArtifactFilesQueryResult = await Api.executeQuery<GetArtifactFilesQuery, GetArtifactFilesQueryResult>(
                ApiQuery.GetArtifactFiles, { artifactId: id, category: ArtifactFileCategory.Image });

            const artifactImages: ArtifactImageListModel[] = [];
            for (const artifactFile of getArtifactFilesQueryResult.rows) {
                const base64ImageData = await Api.getArtifactImageData(id, artifactFile.id);
                const imageTypeName = ArtifactFileTypeDisplayNames[artifactFile.type];
                artifactImages.push({ ...artifactFile, imageData: base64ImageData, imageTypeName })
            }
            setRows(artifactImages);
        } catch (err) {
            setErrors(err.response.data.errors || [err.response.statusText])
        }
    }, []);

    const handleUpload = useCallback(async (file: File, fileType: ArtifactFileType): Promise<ArtifactImageUploadResult> => {
        try {
            await Api.uploadArtifactImageData(artifactId, fileType, file);
            await loadArtifactImages(artifactId);
            return { success: true };
        } catch (err) {
            let errors = [];
            if (err.name === "AxiosError") {
                errors = err?.response?.data?.errors || [];
            } else {
                errors.push("An unknown error occurred. Please try again.");
                console.error(err);
            }

            return { success: false, errors };
        }
    }, [artifactId, loadArtifactImages]);


    const columns: GridColDef[] = [
        imageDataColumn(nameof<ArtifactImageListModel>("imageData"), "Image", imageHeight),
        idColumn(pageSize, nameof<ArtifactImageListModel>("id")),
        textColumn(nameof<ArtifactImageListModel>("imageTypeName"), "Image Type"),
        renderColumn("dimensions", "Dimensions", (params: any) => `${params.row.width} x ${params.row.height}`),
        renderColumn(nameof<ArtifactImageListModel>("sizeBytes"), "Size", (params: any) => `${Math.round(params.row.sizeBytes / 1000)} KB`),
        dateColumn(nameof<ArtifactImageListModel>("createdUtc"), "Created"),
        deleteActionColumn(deleteButtonAccessible, async(rowId)=> { setDeleteRecord({id: rowId, name: `Artifact Image ${rowId}`})} )
    ];


    useMount(() => {

        if (!auth.authState.principal?.hasAnyPermission(permissions)) {
            navigate(paths.error403);
            return;
        }

        setLoading(true);
        loadArtifact(artifactId);
        loadArtifactImages(artifactId);
        setLoading(false);

    });

    return (

        <>
            <Seo title={title} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <Stack spacing={4}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                        
                            <Stack spacing={1}>
                            <Stack spacing={0}>
                                <div>
                                    <Link
                                        color="text.primary"
                                        component={RouterLink}
                                        href={backPath}
                                        sx={{
                                            alignItems: 'center',
                                            display: 'inline-flex'
                                        }}
                                        underline="hover"
                                    >
                                        <SvgIcon sx={{ mr: 1 }}>
                                            <ArrowLeftIcon />
                                        </SvgIcon>
                                        <Typography variant="subtitle2">
                                            {backTitle}
                                        </Typography>
                                    </Link>
                                </div>
                            </Stack>
                                <Typography variant="h4">
                                    {title}
                                </Typography>
                            </Stack>
                           
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={3}
                            >

                                <Button
                                    onClick={uploadDialog.handleOpen}
                                    startIcon={(
                                        <SvgIcon>
                                            <Upload01Icon />
                                        </SvgIcon>
                                    )}
                                    variant="contained"

                                    disabled={!uploadButtonAccessible}
                                >
                                    Upload
                                </Button>

                            </Stack>
                        </Stack>
                        <ArtifactTags artifact={artifact}/>
                        <Card>
                            <UIMessage
                                type="error"
                                messages={errors}
                                onClose={() => setErrors([])} />

                            <UIDataGrid
                                loading={loading}
                                hideFooterPagination={loading}
                                rows={rows}
                                columns={columns}
                                disableColumnSelector={true}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: false,
                                        csvOptions: { disableToolbarButton: true },
                                        printOptions: { disableToolbarButton: true },
                                    },
                                }}
                                rowHeight={imageHeight}
                            />

                        </Card>
                    </Stack>
                </Container>
            </Box>
            <ArtifactImageUploader
                artifact={artifact}
                onClose={uploadDialog.handleClose}
                open={uploadDialog.open}
                onUpload={handleUpload}
            />
        <UIConfirmDialog
			title="Delete Artifact Image?"
			record={deleteRecord}
			onCancel={handleDeleteCanceled}
			onConfirm={(id) => handleDeleteConfirmed(artifact?.id || 0, id)}
		/>
        </>
    );
};

export default ArtifactImagesPage;

