import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { Card, CardHeader, CircularProgress, Paper } from '@mui/material';
import {  useState } from 'react';
import { ArtifactImage, ArtifactLoadingImage } from 'src/models/artifact-file';
import { ArtifactModel } from 'src/models/artifact';
import { ArtifactImageViewer } from './artifact-image-viewer';



const ArtifactImageLoading: React.FC<{ title: string, data: any }> = ({ title, data }) => {
  return (<>
    <img
      src={`data:image/png;base64, ${data}`}
      alt={title}
      loading="lazy"
      style={{ borderRadius: '10px', width: '100%', height: '100%' }}
    />
    <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} />
  </>);
}

const defaultArtifactImages: ArtifactImage[] = [
  {
    data: ArtifactLoadingImage,
    label: 'Front'
  },
  {
    data: ArtifactLoadingImage,
    label: 'Back'
  },
  {
    data: ArtifactLoadingImage,
    label: 'Pre Conservation'
  },

];

interface ArtifactImageListProps {
  artifact: ArtifactModel;
  images: ArtifactImage[];
}


const ArtifactImageList: React.FC<ArtifactImageListProps> = ({ artifact, images }) => {

  const [viewerImage, setViewerImage] = useState<ArtifactImage>();
  const [viewerOpen, setViewerOpen] = useState<boolean>(false);
  const artifactImages = images.length > 0 ? images : defaultArtifactImages;

  const handleOpenImageViewer = (image: ArtifactImage) => {
    setViewerImage(image);
    setViewerOpen(true);
  };

  const handleCloseImageViewer = () => {
    setViewerImage(undefined);
    setViewerOpen(false);
  };


  return (
    <>
      <Card>
        <CardHeader title="Images" />
        <Paper elevation={0} sx={{ pl: 5, pr: 5 }}>
          <ImageList
            sx={{
              minWidth: 100,
              maxWidth: 400,
              minHeight: '100%',
              mx: 'auto',
              // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
              transform: 'translateZ(0)',
            }}

            cols={1}
            gap={10}
          >
            {artifactImages.map((item, index) => {
              const cols = 1;
              const rows = 1;

              return (
                <ImageListItem key={index} cols={cols} rows={rows}>

                  {item.data === ArtifactLoadingImage ? (
                    <ArtifactImageLoading title={item.label} data={item.data} />
                  ) : (


                    <img
                      src={`data:image/png;base64, ${item.data}`}
                      alt={item.label}
                      loading="lazy"
                      style={{ borderRadius: '10px', width: '100%', height: '100%', cursor: 'pointer',   }}
                      onContextMenu={(e)=>e.preventDefault()}
                      onClick={() => handleOpenImageViewer(item)}
                    />


                  )}

                  <ImageListItemBar
                    sx={{
                      borderRadius: '10px',
                      background:
                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    }}
                    title={item.label}
                    position="top"
                    actionIcon={
                      <IconButton
                        sx={{ color: 'white' }}
                        aria-label={`star ${item.label}`}
                      >
                        <PhotoCameraOutlinedIcon />
                      </IconButton>
                    }
                    actionPosition="left"
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        </Paper>
      </Card>
      <ArtifactImageViewer artifact={artifact} artifactImage={viewerImage} open={viewerOpen} onClose={handleCloseImageViewer} />
    </>

  );
}

export default ArtifactImageList;