export enum Permission 
{
	Anonymous = 1,
	Administration = 2,
	DevelopmentFeatures = 3,

	ArtifactsRead = 100,
	ArtifactsCreate = 101,
	ArtifactsEdit = 102,
	ArtifactsTransfer = 103,
	ArtifactsCreateImages = 104,
	ArtifactsDeleteImages = 105,
	ArtifactsExportLatLon = 106,
	
	DataManagementRead = 200,
	DataManagementCreate = 201,
	DataManagementEdit = 202,

	DivisionManagementRead = 300,
	DivisionManagementCreate = 301,
	DivisionManagementEdit = 302,
	
	ArtifactsIssueCertificates = 303,
}