import { DataGrid, DataGridProps, GridToolbar } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "src/shared/components/grid-rows-overlay";


interface UIDataGridProps extends DataGridProps {
	RowPointer?: boolean;
}

export const UIDataGrid = (props: UIDataGridProps) => {
	return (
		<>
			<div style={{ width: '100%', marginTop:0 }}>
				<DataGrid
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 25
							},
						},
					}}
					disableRowSelectionOnClick
					disableColumnFilter
					disableColumnMenu
					disableColumnSelector
					density="comfortable"
					disableDensitySelector
					slots={{ toolbar: GridToolbar,  noRowsOverlay: CustomNoRowsOverlay, }} 
					pageSizeOptions={[5, 25]}
					slotProps={{
						toolbar: {
							showQuickFilter: false,
							csvOptions: { disableToolbarButton: true },
							printOptions: { disableToolbarButton: true },
						},
					}}
					sx={{
						"& .MuiDataGrid-columnHeaderTitle": {
							whiteSpace: "normal",
							lineHeight: "normal"
						},
						"& .MuiDataGrid-columnHeader": {
							// Forced to use important since overriding inline styles
							height: "unset !important",

							backgroundColor: '#F8F9FA',
							fontWeight: 600,
							textTransform: 'uppercase',
							color: '#2F3746',
							letterSpacing: '0.5px',
							fontSize: '12px',
							paddingTop: "10px",
							paddingBottom: "10px"
						},
						"& .MuiDataGrid-columnHeaders": {
							// Forced to use important since overriding inline styles
							maxHeight: "200px !important",
							backgroundColor: '#F8F9FA'
						},
						"& .MuiDataGrid-toolbarContainer": {
							//backgroundColor: '#F8F9FA',
							paddingTop: "10px"
						},
						"& .MuiDataGrid-virtualScroller": {
							minHeight: "100px"
						},
						"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
							outline: "none !important",
						},
						"& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
							outline: "none !important",
						},
						"& .MuiDataGrid-toolbarContainer .MuiButton-textSizeSmall" : {
							color:"#1C2536"
						},
						'& .MuiDataGrid-row:hover': {
							cursor: props.RowPointer ? 'pointer' : 'default'
						}
						
					}}
					{...props}
				/>
			</div>
		</>
	);
}